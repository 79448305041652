* {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* width scrollbar */
::-webkit-scrollbar {
  width: 7px;
  display: block;
}

::-webkit-scrollbar:horizontal {
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #007bff8a;
  border-radius: 8px;
  /* height: 50vh; */
}

/* Track progress bar of the scrollbar */
::-webkit-scrollbar-track {
  background: #14141400;
  box-shadow: inset 0 0 5px rgba(17, 17, 17, 0);
}

::-webkit-scrollbar-track:hover {
  background: #14141400;
  box-shadow: inset 0 0 5px rgba(17, 17, 17, 0.544);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007aff;
}

.swiper {
  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  background: transparent;
  /* height: calc((100% - 30px) / 2) !important; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.arrow-container-products {
  position: relative;

  top: -200px;
  cursor: pointer;
  /* bottom: 50; */
  /* translate: translateY(50%); */
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #007aff; */
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
}

.arrow-container {
  cursor: pointer;
  position: relative;
  top: -50%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1200px;
}

.prev-arrow-carousel {
  z-index: 1000;
  content: "";
  /* display: block; */

  width: 24px;
  height: 24px;

  padding: 3px;

  background-color: rgba(87, 84, 84, 0.465);
  border-radius: 50%;
  /* position: relative; */
  /* top: -50%; */
  /* left: 10%; */

  /* margin-top: -10px; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-arrow-carousel div {
  border: solid rgb(253, 251, 251);
  border-width: 0 2px 2px 0;
  /* display: inline-block; */
  padding: 1.5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.next-arrow-carousel {
  display: inline-block;
  z-index: 1000;
  content: "";
  /* display: block; */
  width: 24px;
  height: 24px;

  padding: 3px;

  background-color: rgba(87, 84, 84, 0.465);
  border-radius: 50%;
  /* position: relative; */
  /* top: -50%; */
  /* left: 80%; */

  /* margin-top: -10px; */

  display: flex;
  justify-content: center;
  align-items: center;
}
.next-arrow-carousel div {
  border: solid rgb(253, 251, 251);
  border-width: 0 2px 2px 0;
  /* display: inline-block; */
  padding: 1.5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
